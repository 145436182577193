import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import { StaticImage } from "gatsby-plugin-image"
import Support from '../components/Support';

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Ahora tienes el control de tu hogar con nuestras APPs
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Es momento de iniciar la nueva era de automatización digital en tu vida y tu(s) Departamento / Hogar 
          </p>
          {/* <p className="mt-8 md:mt-12">
            <Button size="lg">Get Started</Button>
          </p>
          <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="lg:w-1/2">
          <div className="bg-white overflow-hidden shadow rounded-lg ml-10">
            <div className="px-4 py-5 sm:p-6 flex items-center justify-center">
              <HeroImage />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="apps" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Nuestras APPs</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8 ">
            <p className="mb-2 font-light text-xl">Edificio</p>
              <StaticImage src="../images/genz.png" width={100} height={100} alt="SmartBO" />
              
              {/* <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p> */}
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="mb-2 font-light text-xl">Edificio:</p>
              <StaticImage src="../images/gold.png" width={100} height={100} alt="SmartBO" />
              
              {/* <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p> */}
            </Card>
          </div>
          {/* <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Service Three</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div> */}
        </div>
      </div>
    </section>
    <SplitSection
      id="features"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Controles de acceso</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Obtenga control absoluto sobre sus ingresos y el de sus visitas. 
          </p>
        </div>
      }
      secondarySlot={<div className="bg-white overflow-hidden shadow rounded-lg"><div className="px-4 py-5 sm:p-6"><StaticImage src="../images/undraw_Login_re_4vu2.png" height={300} alt="control-accesso"/></div></div>}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Vigilancia
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Ahora es posible que usted tenga acceso a las camaras de vigilancia en la palma de su mano
          </p>
        </div>
      }
      secondarySlot={<div className="bg-white overflow-hidden shadow rounded-lg"><div className="px-4 py-5 sm:p-6"><StaticImage src="../images/undraw_Surveillance_re_8tkl.png" height={300} alt="vigilancia"/></div></div>}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Casilleros Inteligentes
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Nuestra plataforma permite el uso de casilleros inteligentes. Ahora no es necesario que usted este en casa para recibir sus encomiendas.
          </p>
        </div>
      }
      secondarySlot={<div className="bg-white overflow-hidden shadow rounded-lg"><div className="px-4 py-5 sm:p-6"><StaticImage src="../images/undraw_package_arrived_63rf.png" height={300} alt="casilleros"/></div></div>}
    />
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section id="support" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <Support/>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Listo para dar el siguiente paso?</h3>
      <p className="mt-8 text-xl font-light">
        Contactenos para consultar sobre nuestro servicio y planes.
      </p>
      <p className="mt-8">
        <Button size="xl"> <a href="https://wa.me/59167547717" className="text-white">Chatea con nosotros</a></Button>
      </p>
    </section>
  </Layout>
);

export default Index;
